import { IWebConfig } from '../models/discoveryConfig';
import { ICreateRecord, IRecord } from '../models/record';
import { ICreateWallet, IWallet } from '../models/wallet';

import http from './http';

const Bookmarks = {
  create: (id: string) => http.put(`/records/${id}/bookmarks`),
  delete: (id: string) => http.delete(`/records/${id}/bookmarks`),
};

const Records = {
  details: (id: string) => http.get<IRecord>(`/records/${id}`),
  list: (params: string = '') => http.get<IRecord[]>(`/records${params}`),
  create: (record: ICreateRecord) => http.post<IRecord>('/records', record),
  getPending: () => http.get<IRecord[]>(`/records?category=pending`),
};

const Wallets = {
  list: () => http.get<IWallet[]>('/wallets'),
  create: () => http.post<ICreateWallet>('/wallets'),
  delete: (id: string) => http.delete(`/wallets/${id}`),
};

const WebConfig = {
  get: () => http.get<IWebConfig>(`/discovery`),
};

const User = {
  sendConfirmationEmail: () => http.post('/users/email/confirmation'),
};

const api = {
  Bookmarks,
  Records,
  User,
  Wallets,
  WebConfig,
};

export default api;
