import { observer } from 'mobx-react-lite';
import { Switch, Route } from 'react-router-dom';
import ConfirmEmail from '../components/ConfirmEmail';
import Search from '../features/Search';
import Details from '../features/Details';
import Proof from '../features/Proof';
import { searchPath, detailsPath, proofPath } from './constants/routes';
import { useStore } from './stores/store';

const Router = () => {
  const {
    userStore: { loggedIn, user },
  } = useStore();

  const emailUnconfirmed = loggedIn && user && !user.profile.email_verified;

  if (emailUnconfirmed) {
    return (
      <Route>
        <ConfirmEmail />
      </Route>
    );
  }

  return (
    <Switch>
      <Route path={searchPath}>
        <Search />
      </Route>

      <Route path={detailsPath}>
        <Details />
      </Route>

      <Route path={proofPath}>
        <Proof />
      </Route>

      <Route>
        <Search />
      </Route>
    </Switch>
  );
};

export default observer(Router);
