import { useState } from 'react';
import Button from '@material-ui/core/Button';

const CookieDialog = () => {
  const [expand, setExpand] = useState(false);
  return (
    <div>
      <p>
        This website does not use cookies. However, the login via Identity
        Server does. CoreLedger’s Identity Server will display their own
        policies.
      </p>

      <p>
        Notardec identifies service requests by the IP addresses of the visitor.
        The IP addresses will not be logged but used to define the requests
        geographical region.
      </p>

      <p>Service provided by Notardec GmbH</p>
      {!expand && (
        <Button color="primary" variant="text" onClick={() => setExpand(true)}>
          Expand
        </Button>
      )}

      {expand && (
        <div>
          <h3>Detailed Explanation</h3>
          <p>
            Switzerland does not enforce laws about cookie notices. However,
            almost all cookies fall under the definition of "personal data" of
            the GDPR (Art.6 Abs. 1 lit.f DSGVO, Cookies). Cookies are text files
            that are stored on your computer and save certain data about your
            user behavior on our site. This website does not use any cookies.
            However, the login via Identity Server does. They use so-called
            "session cookies", which are automatically deleted at the end of
            your visit to our website. But there are also cookies that are
            permanently stored on your computer if you do not delete them.
            However, Notardec GmbH does not use this cookies in any other way as
            for their designated usage.
          </p>

          <p>
            Notardec GmbH and its product NOTRZR do not create a personality
            profile as stated in Switzerland’s Federal Act on Data Protection
            (FADP). However, Notardec identifies service requests by the IP
            addresses of the visitor. The IP addresses will not be logged but
            used to define the requests geographical region. This data is only
            stored in the context to specific requests made on NOTRZR and are
            not linked to any user, no matter if authenticated or authorized.
            For any further usage of your data, see our extended Policies (LINK)
            and the Privacy Policy of CoreLedger’s Identity Server.
          </p>

          <p>
            This service is provided by:
            <br />
            Notardec GmbH
            <br />
            Schutzengelstrasse 36
            <br />
            6340 Baar, Zug
            <br />
            Switzerland
          </p>
        </div>
      )}
    </div>
  );
};

export default CookieDialog;
