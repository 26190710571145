const getItem = (key: string): string | null => {
  return localStorage.getItem(key);
};

const setItem = (key: string, value: string) => {
  localStorage.setItem(key, value);
};

const storage = {
  getItem,
  setItem,
};

export default storage;

export const storageKeys = {
  POST_IS_REDIRECT_URL: 'POST_IS_REDIRECT_URL',
  COOKIE_POLICY_ACCEPTED: 'COOKIE_POLICY_ACCEPTED',
};
