import { action, makeObservable, observable } from 'mobx';
import { matchPath } from 'react-router';
import CookieDialog from '../../components/CookieDialog';
import {
  emailConfirmationPath,
  identityServerRedirectPath,
  searchPath,
} from '../constants/routes';
import { IError } from '../models/error';
import { User } from '../models/user';
import storage, { storageKeys } from '../utils/storage';
import { store } from './store';

export default class CommonStore {
  accessToken: string | undefined;
  appLoading = true;
  appError: IError | null = null;
  cookiePolicyAccepted = true;

  constructor() {
    makeObservable(this, {
      appLoading: observable,
      accessToken: observable,
      appError: observable,
      checkAuth: action,
      setAppLoading: action,
      setAccessToken: action,
      setAppError: action,
      acceptCookiePolicy: action,
    });
  }

  setAppLoading = (appLoading: boolean) => {
    this.appLoading = appLoading;
  };

  setAccessToken = (accessToken: string | undefined) => {
    this.accessToken = accessToken;
  };

  setAppError = (appError: IError | null) => {
    this.appError = appError;
  };

  initialize = () => {
    setTimeout(() => {
      this.setAppLoading(false);
    }, 1200);
  };

  checkAuth = async () => {
    let authCheckFinished = false;
    let timeoutElapsed = false;
    setTimeout(() => {
      timeoutElapsed = true;
      if (authCheckFinished) {
        this.setAppLoading(false);
      }
    }, 1500);

    const isReturningFromIS = matchPath(
      store.navigationStore.history.location.pathname,
      {
        path: identityServerRedirectPath,
      }
    );

    if (isReturningFromIS) {
      await store.userStore.loginCallback();
    }
    const user = await store.userStore.loadUser();
    if (user) {
      this.setAccessToken(user.access_token);
      this.acceptCookiePolicy();
      const emailConfirmed = this.checkEmailConfirmation(user);
      if (!emailConfirmed) return;
    }

    if (isReturningFromIS) {
      const redirectUrl =
        storage.getItem(storageKeys.POST_IS_REDIRECT_URL) || searchPath;
      store.navigationStore.replace(redirectUrl);
    }

    authCheckFinished = true;
    if (timeoutElapsed) {
      this.setAppLoading(false);
    }
  };

  checkEmailConfirmation = (user: User) => {
    if (!user.profile.email_verified) {
      store.navigationStore.replace(emailConfirmationPath);
      this.setAppLoading(false);
      return false;
    }
    return true;
  };

  cookiePolicyCheck = () => {
    if (!storage.getItem(storageKeys.COOKIE_POLICY_ACCEPTED)) {
      store.dialogStore.setDialogConfig({
        open: true,
        onClose: store.dialogStore.closeDialog,
        onCancel: store.dialogStore.closeDialog,
        onConfirm: this.acceptCookiePolicy,
        title: 'Cookies & Personal Data Notice',
        component: CookieDialog,
      });
    } else {
      this.acceptCookiePolicy();
    }
  };

  acceptCookiePolicy = () => {
    storage.setItem(storageKeys.COOKIE_POLICY_ACCEPTED, 'true');
    this.cookiePolicyAccepted = true;
  };
}
