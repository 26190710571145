import { createContext, useContext } from 'react';
import CommonStore from './commonStore';
import NavigationStore from './navigationStore';
import UserStore from './userStore';
import RecordStore from './recordStore';
import WalletStore from './walletStore';
import DialogStore from './dialogStore';

interface Store {
  commonStore: CommonStore;
  userStore: UserStore;
  navigationStore: NavigationStore;
  recordStore: RecordStore;
  walletStore: WalletStore;
  dialogStore: DialogStore;
}

export const store: Store = {
  commonStore: new CommonStore(),
  userStore: new UserStore(),
  navigationStore: new NavigationStore(),
  recordStore: new RecordStore(),
  walletStore: new WalletStore(),
  dialogStore: new DialogStore(),
};

export const StoreContext = createContext(store);

export function useStore() {
  return useContext(StoreContext);
}
