import { CircularProgress } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { ITheme } from '../../common/theme';

const useStyles = makeStyles((theme: ITheme) =>
  createStyles({
    loadingPage: {
      width: '100%',
      height: '100vh',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: theme.palette.background.default,
    },
  })
);

const LoadingPage = () => {
  const classes = useStyles();
  return (
    <div className={classes.loadingPage}>
      <CircularProgress color={'primary'} />
    </div>
  );
};

export default LoadingPage;
