import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/styles';
import theme from './common/theme';
import { store, StoreContext } from './common/stores/store';
import Container from './common/Container';
import { Router as BrowserRouter } from 'react-router';

function App(): JSX.Element {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter history={store.navigationStore.history}>
        <StoreContext.Provider value={store}>
          <Container />
        </StoreContext.Provider>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
