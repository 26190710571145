import {
  createStyles,
  makeStyles,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  useMediaQuery,
  useTheme,
  Typography,
} from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { ITheme } from '../../common/theme';
import clsx from 'clsx';
import { CheckCircle } from '@material-ui/icons';
import './proof.css';

const useStyles = makeStyles((theme: ITheme) =>
  createStyles({
    topbar: {
      ...theme.mixins.toolbar, // necessary for content to be below top bar
    },
    formWrapper: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    actionWrapper: {
      alignSelf: 'flex-start',
      padding: theme.spacing(1),
    },
    actionButton: {
      marginRight: theme.spacing(2),
    },
    formGroupWrapper: {
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        alignItems: 'center',
      },
    },
    formControl: {
      maxWidth: '540px',
      marginBottom: theme.spacing(2),
      width: '100%',
      border: '2px solid',
      borderColor: theme.palette.primary.main,
      borderRadius: '6px',
      padding: theme.spacing(1),
      margin: theme.spacing(1),
      '& .Mui-disabled': {
        color: 'rgba(0, 0, 0, 0.75)',
      },
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    idField: {
      [theme.breakpoints.up('md')]: {
        width: '50%',
      },
    },
    nameField: {
      [theme.breakpoints.up('md')]: {
        width: '50%',
      },
    },
    bookmarkButton: {
      textTransform: 'capitalize',
      [theme.breakpoints.up('md')]: {
        width: '20%',
      },
    },
    authorField: {
      [theme.breakpoints.up('md')]: {
        width: '50%',
      },
    },
    timestampField: {
      [theme.breakpoints.up('md')]: {
        width: '50%',
      },
    },
    hashField: {
      [theme.breakpoints.up('md')]: {
        width: '45%',
      },
    },
    checkIcon: {
      marginTop: theme.spacing(6),
      alignSelf: 'center',
      fontSize: '8rem',
    },
    formLabel: {
      padding: theme.spacing(1),
    },
    activateButton: {
      alignSelf: 'flex-start',
      ...theme.mixins.defaultButton,
      ...theme.mixins.wideButton,
    },
  })
);

const Proof = () => {
  const classes = useStyles();
  const theme = useTheme();
  const matchesSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <form className={classes.formWrapper} autoComplete="off">
      {matchesSmallScreen ? null : <div className={classes.topbar} />}

      {/* <div */}
      {/*   className={classes.formGroupWrapper} */}
      {/*   style={{ justifyContent: 'flex-start' }} */}
      {/* > */}
      {/*   <div className={classes.actionWrapper}> */}
      {/*     <Button */}
      {/*       className={classes.actionButton} */}
      {/*       color="primary" */}
      {/*       variant="contained" */}
      {/*     > */}
      {/*       <FileCopyRounded /> */}
      {/*     </Button> */}
      {/*     <Button */}
      {/*       className={classes.actionButton} */}
      {/*       color="primary" */}
      {/*       variant="contained" */}
      {/*     > */}
      {/*       <DeveloperMode /> */}
      {/*     </Button> */}
      {/*   </div> */}
      {/* </div> */}
      <div className={classes.formGroupWrapper}>
        <FormControl className={clsx(classes.formControl, classes.nameField)}>
          <InputLabel
            className={classes.formLabel}
            id="wallet-for-record-label"
          >
            Photo ID Type
          </InputLabel>
          <Select
            labelId="wallet-for-record-label"
            id="wallet-select"
            value={'Passport'}
            disabled={true}
            IconComponent={Test}
          >
            <MenuItem value="Passport">Passport</MenuItem>
          </Select>
        </FormControl>

        <TextField
          color="primary"
          size={'small'}
          value={'100003106'}
          label={'Photo ID Provided'}
          disabled={true}
          variant="standard"
          className={clsx(classes.formControl, classes.nameField)}
          InputLabelProps={{
            className: classes.formLabel,
          }}
        />
      </div>
      <div className={classes.formGroupWrapper}>
        <TextField
          color="primary"
          size={'small'}
          value={'SARS-CoV-2'}
          label={'Reason'}
          disabled={true}
          variant="standard"
          className={clsx(classes.formControl, classes.authorField)}
          InputLabelProps={{
            className: classes.formLabel,
          }}
        />
        <TextField
          color="primary"
          size={'small'}
          value={new Date().toLocaleString()}
          label={'Timestamp'}
          disabled={true}
          variant="standard"
          className={clsx(classes.formControl, classes.timestampField)}
          InputLabelProps={{
            className: classes.formLabel,
          }}
        />
      </div>
      <CheckCircle
        className={clsx(classes.checkIcon, 'bounce')}
        color="primary"
      />

      <div
        style={{
          marginTop: '2rem',
          display: 'flex',
          flexDirection: 'column',
          maxWidth: '580px',
          marginLeft: 'auto',
          marginRight: 'auto',
          color: theme.palette.primary.main,
        }}
      >
        <Typography variant="h6" color="primary" align="center">
          This is the proof page of a blockchain entry recorded and provided by
          Vaccine Notar (VAXNTR).
        </Typography>
        <ol
          style={{
            marginLeft: 'auto',
            marginRight: 'auto',
            marginTop: '1rem',
            fontSize: '1.125rem',
          }}
        >
          <li>
            Always check for the lock icon in your browser to make sure you are
            safely connected to vaxntr.com when scanning for proof.
          </li>
          <li>
            Make sure the proof page shows a green circle with a checkmark to
            confirm the validity of the record.
          </li>
          <li>
            Ask for owners Photo-ID and compare it to the data provided by the
            record
          </li>
        </ol>
      </div>
    </form>
  );
};

export default observer(Proof);

const Test = () => <span></span>;
