import {
  Button,
  ButtonProps,
  Card,
  CardContent,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { InfoOutlined } from '@material-ui/icons';
import clsx from 'clsx';
import { ITheme } from '../../common/theme';

const useStyles = makeStyles((theme: ITheme) => ({
  wrapper: { ...theme.mixins.wrapper },
  cardHeader: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
  },
  infoIcon: {
    marginRight: theme.spacing(1),
  },
  root: {
    display: 'flex',
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    maxWidth: '640px',
  },
  cardContainer: {
    display: 'flex',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  cardSubtitle: {
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  authButtonsContainer: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
    alignItems: 'center',
  },
  leftButton: {
    ...theme.mixins.defaultButton,
    background: theme.palette.common.white,
    color: theme.palette.primary.main,
  },
  leftMargin: {
    marginLeft: theme.spacing(1),
  },
  rightMargin: {
    marginRight: theme.spacing(1),
  },
  rightButton: {
    ...theme.mixins.defaultButton,
    color: theme.palette.common.white,
    borderColor: theme.palette.common.white,
  },
}));

export interface IInfoBoxButton {
  variant: ButtonProps['variant'];
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  text: string;
  disabled?: boolean;
}

export interface IPropsInfoBox {
  title: string;
  subtitle: string;
  leftButton?: IInfoBoxButton;
  rightButton?: IInfoBoxButton;
}
const InfoBox = ({
  title,
  subtitle,
  leftButton,
  rightButton,
}: IPropsInfoBox) => {
  const classes = useStyles();

  const LeftButtonComponent = leftButton ? (
    <Button
      variant={leftButton.variant}
      onClick={leftButton.onClick}
      className={clsx(
        classes.leftButton,
        rightButton ? classes.rightMargin : ''
      )}
      disabled={leftButton.disabled}
    >
      {leftButton.text}
    </Button>
  ) : null;

  const RightButtonComponent = rightButton ? (
    <Button
      variant={rightButton.variant}
      onClick={rightButton.onClick}
      className={clsx(
        classes.rightButton,
        leftButton ? classes.leftMargin : ''
      )}
      disabled={rightButton.disabled}
    >
      {rightButton.text}
    </Button>
  ) : null;

  return (
    <div className={classes.wrapper}>
      <Card className={classes.root} raised>
        <div className={classes.cardContainer}>
          <CardContent>
            <div className={classes.cardHeader}>
              <InfoOutlined className={classes.infoIcon} />
              <Typography component="h5" variant="h5">
                {title}
              </Typography>
            </div>
            <Typography variant="subtitle1" className={classes.cardSubtitle}>
              {subtitle}
            </Typography>
          </CardContent>

          <CardContent className={classes.authButtonsContainer}>
            {LeftButtonComponent}
            {RightButtonComponent}
          </CardContent>
        </div>
      </Card>
    </div>
  );
};

export default InfoBox;
