import { makeObservable, observable, action } from 'mobx';
import api from '../api';
import {
  UserManager as OidcUserManager,
  UserManagerSettings,
  WebStorageStateStore,
} from 'oidc-client';
import { User } from '../models/user';
import { store } from './store';
import errorNotify from '../utils/notifications/errorNotify';
import storage, { storageKeys } from '../utils/storage';

class UserManager {
  private static instance: OidcUserManager;
  private constructor() {}

  public static initialize(userManagerSettings: UserManagerSettings): void {
    UserManager.instance = new OidcUserManager(userManagerSettings);
  }

  public static getInstance(): OidcUserManager {
    if (!UserManager.instance) {
      throw new Error(
        'OidcUserManager instance was accessed without being initialized.'
      );
    }
    return UserManager.instance;
  }
}

export default class UserStore {
  loggedIn = true;
  user: User | null = null;

  constructor() {
    makeObservable(this, {
      loggedIn: observable,
      loadUser: action,
      setLoggedIn: action,
    });
  }

  initUserManager = async () => {
    const { value } = await api.WebConfig.get();

    UserManager.initialize({
      authority: value.endpoints.authApi,
      client_id: value.authConfig.clientId,
      redirect_uri: value.authConfig.redirectUri,
      post_logout_redirect_uri: value.authConfig.postLogoutRedirectUri,
      response_type: value.authConfig.responseType,
      scope: value.authConfig.scopes,
      userStore: new WebStorageStateStore({ store: localStorage }),
    });
  };

  setLoggedIn = (loggedIn: boolean) => {
    this.loggedIn = loggedIn;
  };

  login = () => {
    store.commonStore.setAppLoading(true);
    storage.setItem(storageKeys.POST_IS_REDIRECT_URL, window.location.pathname);
    const userManager = UserManager.getInstance();
    userManager.clearStaleState().then(() => userManager.signinRedirect());
  };

  logout = () => {
    // store.commonStore.setAppLoading(true);
    // store.commonStore.setAccessToken(undefined);
    // this.user = null;
    // this.setLoggedIn(false);
    // UserManager.getInstance().signoutRedirect();
  };

  loadUser = async () => {
    this.user = await UserManager.getInstance().getUser();
    if (this.user) {
      this.setLoggedIn(true);
    }
    return this.user;
  };

  removeUser = async () => {
    await UserManager.getInstance().removeUser();
  };

  loginCallback = async () => {
    await UserManager.getInstance().signinCallback();
  };

  sendEmailConfirmation = async () => {
    try {
      await api.User.sendConfirmationEmail();
    } catch (_err) {
      errorNotify(
        'Failed so send confirmation email. Please try again or contact support.'
      );
    }
  };
}
