import { createStyles, makeStyles } from '@material-ui/core';
import { useStore } from '../../common/stores/store';
import { ITheme } from '../../common/theme';
import InfoBox from '../InfoBox';

const useStyles = makeStyles((theme: ITheme) =>
  createStyles({
    wrapper: theme.mixins.fullstopWrapper,
  })
);

const ConfirmEmail = () => {
  const classes = useStyles();
  const { commonStore, userStore } = useStore();

  const checkEmailConfirmation = () => {
    commonStore.setAppLoading(true);
    setTimeout(async () => {
      await userStore.removeUser();
      userStore.login();
    }, 1000);
  };

  return (
    <div className={classes.wrapper}>
      <InfoBox
        title="Email unconfirmed"
        subtitle="Please confirm your email"
        leftButton={{
          variant: 'contained',
          onClick: checkEmailConfirmation,
          text: 'Check again',
        }}
        rightButton={{
          variant: 'outlined',
          onClick: userStore.sendEmailConfirmation,
          text: 'Resend',
        }}
      />
    </div>
  );
};

export default ConfirmEmail;
