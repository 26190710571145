import {
  Drawer,
  Divider,
  Hidden,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Box,
  Button,
  useTheme,
} from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Logo from '../Logo';
import { Android, Apple, Assignment } from '@material-ui/icons';
import clsx from 'clsx';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { ITheme } from '../../common/theme';
import { useStore } from '../../common/stores/store';
import { observer } from 'mobx-react-lite';
import { matchPath } from 'react-router';
import { searchPath } from '../../common/constants/routes';
import { SyntheticEvent } from 'react';

export const drawerWidth = 240;
const txAndroidAppLink =
  'https://play.google.com/store/apps/details?id=com.coreledger.txmobile.android';
const txAppleAppLink = 'https://apps.apple.com/us/app/tx-mobile/id1459637926';

const useStyles = makeStyles((theme: ITheme) =>
  createStyles({
    drawer: {
      [theme.breakpoints.up('md')]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    sidebarItem: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    sidebarItemText: {
      textTransform: 'uppercase',
      color: theme.palette.primary.main,
      fontWeight: theme.typography.fontWeightMedium,
      paddingBottom: '3px',
    },
    sidebarItemTextActive: {
      borderBottom: '3px solid',
      borderBottomColor: theme.palette.primary.main,
      paddingBottom: 0,
    },
    sidebarIcon: {
      minWidth: '42px',
      color: theme.palette.primary.main,
    },
    sidebarInnerContainer: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
      width: drawerWidth,
    },
    activeNavLink: {
      color: 'pink',
    },
    buttonsContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginTop: 'auto',
      padding: theme.spacing(2),
    },
    button: {
      ...theme.mixins.defaultButton,
      width: '164px',
      marginBottom: theme.spacing(2),
    },
  })
);

interface IProps {
  mobileOpen: boolean;
  handleDrawerToggle: () => void;
}

const Sidebar = ({ mobileOpen, handleDrawerToggle }: IProps): JSX.Element => {
  const classes = useStyles();
  const theme = useTheme();
  const { pathname } = useLocation();
  const { userStore } = useStore();

  const login = () => {
    userStore.login();
  };

  const logout = () => {
    userStore.logout();
  };

  const isMatchCurrentPath = (path: string, config?: { exact: boolean }) =>
    Boolean(
      matchPath(pathname, {
        path,
        exact: config?.exact || false,
      })
    );

  const drawer = (
    <div className={classes.sidebarInnerContainer}>
      <div className={classes.toolbar}>
        <Logo inSidebar={true} />
      </div>
      <Divider />
      <List>
        <Link component={RouterLink} to={searchPath} underline={'none'}>
          <ListItem className={classes.sidebarItem} button key="Sign">
            <ListItemIcon classes={{ root: classes.sidebarIcon }}>
              <Assignment />
            </ListItemIcon>
            <ListItemText
              classes={{
                primary: `${classes.sidebarItemText} ${
                  isMatchCurrentPath(searchPath) ||
                  isMatchCurrentPath('/', { exact: true })
                    ? classes.sidebarItemTextActive
                    : ''
                }`,
              }}
              primary={'Sign'}
            />
          </ListItem>
        </Link>
      </List>

      <Box className={classes.buttonsContainer}>
        <Button
          className={clsx(classes.button)}
          variant={'outlined'}
          color="primary"
          startIcon={<Android />}
          target="_blank"
          href={txAndroidAppLink}
        >
          VAXNTR APP
        </Button>

        <Button
          className={clsx(classes.button)}
          variant={'outlined'}
          color="primary"
          startIcon={<Apple />}
          target="_blank"
          href={txAppleAppLink}
        >
          VAXNTR APP
        </Button>

        {userStore.loggedIn ? (
          <Button
            className={classes.button}
            variant={'text'}
            color={'primary'}
            onClick={logout}
          >
            Logout
          </Button>
        ) : (
          <>
            <Button
              className={clsx(classes.button)}
              variant={'contained'}
              color={'primary'}
              onClick={login}
            >
              Login
            </Button>
            <Button
              className={classes.button}
              variant={'text'}
              color={'primary'}
              onClick={login}
            >
              Register
            </Button>
          </>
        )}
        <Link
          href="https://www.notardec.com"
          onClick={(event: SyntheticEvent) => event.preventDefault()}
        >
          Imprint
        </Link>
      </Box>
    </div>
  );

  return (
    <nav className={classes.drawer} aria-label="mailbox folders">
      {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
      <Hidden mdUp implementation="css">
        <Drawer
          variant="temporary"
          anchor={theme.direction === 'rtl' ? 'right' : 'left'}
          open={mobileOpen}
          onClose={handleDrawerToggle}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {drawer}
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <Drawer
          classes={{
            paper: classes.drawerPaper,
          }}
          variant="permanent"
          open
        >
          {drawer}
        </Drawer>
      </Hidden>
    </nav>
  );
};

export default observer(Sidebar);
