import { useMediaQuery, useTheme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { ToastContainer, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Topbar from '../components/Topbar';
import Sidebar from '../components/Sidebar';
import { ITheme } from './theme';
import { useStore } from './stores/store';
import LoadingPage from '../components/LoadingPage/LoadingPage';
import Router from './Router';
import Dialog from './Dialog';
import ErrorInfoBox from '../components/ErrorInfoBox';

const useStyles = makeStyles((theme: ITheme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    topbar: {
      ...theme.mixins.toolbar, // necessary for content to be below top bar
    },
    mainContent: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      padding: theme.spacing(3),
      minHeight: '100vh',
    },
  })
);

const Container = () => {
  const { commonStore } = useStore();
  const theme = useTheme();
  const classes = useStyles();
  const matchesSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  useEffect(() => {
    commonStore.initialize();
  }, [commonStore]);

  return commonStore.appLoading ? (
    <LoadingPage />
  ) : (
    <div className={classes.root}>
      {matchesSmallScreen ? (
        <Topbar handleDrawerToggle={handleDrawerToggle} />
      ) : null}

      <Sidebar
        mobileOpen={mobileOpen}
        handleDrawerToggle={handleDrawerToggle}
      />

      <main className={classes.mainContent}>
        {matchesSmallScreen ? <div className={classes.topbar} /> : null}

        {commonStore.appError ? (
          <ErrorInfoBox
            title={commonStore.appError.title}
            subtitle={commonStore.appError.message}
          />
        ) : (
          <Router />
        )}
      </main>
      <Dialog />

      <ToastContainer
        position="bottom-right"
        transition={Slide}
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        toastStyle={{
          backgroundColor: '#455a64',
        }}
      />
    </div>
  );
};

export default observer(Container);
