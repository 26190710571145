import InfoBox, { IPropsInfoBox } from '../InfoBox/Infobox';
import { makeStyles, createStyles } from '@material-ui/core';
import { ITheme } from '../../common/theme';

const useStyles = makeStyles((theme: ITheme) =>
  createStyles({
    verticalAlign: {
      ...theme.mixins.wrapper,
      ...theme.mixins.verticalAlign,
      justifyContent: 'center',
      minHeight: '80%',
    },
  })
);
const ErrorInfoBox = ({ title, subtitle }: IPropsInfoBox) => {
  const classes = useStyles();
  return (
    <div className={classes.verticalAlign}>
      <InfoBox
        title={title}
        subtitle={subtitle}
        leftButton={{
          variant: 'contained',
          text: 'Reload',
          onClick: () => window.location.reload(),
        }}
      />
    </div>
  );
};

export default ErrorInfoBox;
