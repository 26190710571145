import { makeAutoObservable } from 'mobx';
import { IDialogConfig } from '../models/dialogConfig';

export default class DialogStore {
  dialogConfig: IDialogConfig = {
    open: false,
    onClose: () => {},
    onCancel: () => {},
    onConfirm: () => Promise.resolve(),
    title: '',
    component: null,
    text: '',
  };

  constructor() {
    makeAutoObservable(this);
  }

  setDialogConfig = (dialogConfig: IDialogConfig) => {
    this.dialogConfig = dialogConfig;
  };

  openDialog = () => {
    this.dialogConfig.open = true;
  };

  closeDialog = () => {
    this.dialogConfig.open = false;
  };
}
