import React from 'react';
import { useState } from 'react';
import {
  createStyles,
  makeStyles,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Button,
  CircularProgress,
  Typography,
} from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { ITheme } from '../../common/theme';
import clsx from 'clsx';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { useStore } from '../../common/stores/store';
import { proofPath } from '../../common/constants/routes';
import QRCode from 'qrcode.react';
import { FileCopy, Print } from '@material-ui/icons';
const useStyles = makeStyles((theme: ITheme) =>
  createStyles({
    verticalAlign: {
      ...theme.mixins.verticalAlign,
      height: '100%',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    wrapper: { ...theme.mixins.wrapper, marginBottom: theme.spacing(2) },
    formWrapper: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      padding: theme.spacing(1),
      alignItems: 'center',
    },
    formControl: {
      marginBottom: theme.spacing(2),
      maxWidth: '540px',
      width: '100%',
      border: '2px solid',
      borderColor: theme.palette.primary.main,
      borderRadius: '6px',
      padding: theme.spacing(2),
      '& .Mui-disabled': {
        color: 'rgba(0, 0, 0, 0.75)',
      },
      '& .MuiInput-underline:before': {
        borderBottomColor: theme.palette.primary.main,
      },
      '& .MuiInput-underline:hover:before': {
        borderBottomColor: theme.palette.primary.main,
      },
    },
    doctorField: {
      border: 0,
      width: '20%',
      alignSelf: 'flex-end',
      marginBottom: '3em',
      [theme.breakpoints.down('sm')]: {
        width: 'auto',
        alignSelf: 'initial',
        // width: '50%',
      },
    },
    formLabel: {
      padding: theme.spacing(2),
    },
    textField: {
      maxWidth: '100%',
    },
    buttonWrapper: {
      marginTop: '16px',
      display: 'flex',
      justifyContent: 'space-between',
      maxWidth: '540px',
      width: '100%',
    },
    activateButton: {
      ...theme.mixins.defaultButton,
    },
  })
);

const Details = () => {
  const classes = useStyles();
  const { navigationStore } = useStore();
  const [isLoading, setIsLoading] = useState(false);
  const [currentState, setCurrentState] = useState('Decrypting the record');
  const [selectedDate] = React.useState<Date | null>(
    new Date(Date.now() + 3600 * 1000 * 24 * (30 * 3 + 31 * 3))
  );
  const openProof = () => {
    setIsLoading(true);
    setTimeout(
      () => setCurrentState('Successfully decrypted the record'),
      2900
    );
    setTimeout(() => {
      navigationStore.push(proofPath);
    }, 3900);
  };

  const Form = (
    <form className={classes.formWrapper} autoComplete="off">
      <TextField
        color="primary"
        size={'small'}
        label={`Doctor`}
        variant="standard"
        className={clsx(classes.formControl, classes.doctorField)}
        InputLabelProps={{
          className: classes.formLabel,
        }}
        disabled={true}
        value={`Dr. Sue Park`}
      />
      <Typography
        variant={'h5'}
        color="primary"
        align="center"
        style={{ marginBottom: '1em' }}
      >
        The record was successfully encrypted and added to the blockchain.
      </Typography>
      <div className={classes.wrapper}>
        <QRCode
          value={window.location.host + proofPath}
          includeMargin={true}
          size={256}
        />
        <Typography color="primary" align="center" style={{ marginTop: '8px' }}>
          Scan the QR code above with your phone
        </Typography>
      </div>

      <FormControl className={classes.formControl}>
        <InputLabel className={classes.formLabel} id="wallet-for-record-label">
          Photo ID Type
        </InputLabel>
        <Select
          labelId="wallet-for-record-label"
          id="wallet-select"
          value={'Passport'}
          disabled={true}
          IconComponent={Test}
        >
          <MenuItem value="Passport">Passport</MenuItem>
        </Select>
      </FormControl>

      <TextField
        color="primary"
        size={'small'}
        label={`Photo ID Provided`}
        value={'100003106'}
        disabled={true}
        data-fieldid={'photoId'}
        variant="standard"
        className={classes.formControl}
        InputLabelProps={{
          className: classes.formLabel,
        }}
      />

      <TextField
        color="primary"
        size={'small'}
        label={`Reason`}
        disabled={true}
        value={'SARS-CoV-2'}
        variant="standard"
        className={classes.formControl}
        InputLabelProps={{
          className: classes.formLabel,
        }}
      />

      <TextField
        color="primary"
        size={'small'}
        label={`Manufacturer`}
        data-fieldid={'photoId'}
        value={'Moderna'}
        disabled={true}
        variant="standard"
        className={classes.formControl}
        InputLabelProps={{
          className: classes.formLabel,
        }}
      />

      <TextField
        color="primary"
        size={'small'}
        label={`Drug Name`}
        data-fieldid={'photoId'}
        variant="standard"
        value="Moderna (COVID-19 MOD 10 CTN)"
        disabled={true}
        className={classes.formControl}
        InputLabelProps={{
          className: classes.formLabel,
        }}
      />

      <TextField
        datatype="lot-number"
        inputProps={{
          'data-fieldid': 'lot-number',
        }}
        color="primary"
        size={'small'}
        label={`Lot Nr.`}
        data-fieldid={'photoId'}
        variant="standard"
        disabled={true}
        value={'011J20A'}
        className={classes.formControl}
        InputLabelProps={{
          className: classes.formLabel,
        }}
      />

      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          className={classes.formControl}
          InputLabelProps={{
            className: classes.formLabel,
          }}
          disableToolbar
          variant="inline"
          format="MM/dd/yyyy"
          margin="normal"
          id="date-picker-inline"
          label="Exp. Date"
          disabled={true}
          value={selectedDate}
          onChange={console.log}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
      </MuiPickersUtilsProvider>

      <div className={classes.buttonWrapper}>
        <Button
          className={classes.activateButton}
          variant="outlined"
          color="primary"
          endIcon={<FileCopy />}
        >
          Copy
        </Button>
        <Button
          className={classes.activateButton}
          variant="outlined"
          color="primary"
          endIcon={<Print />}
        >
          Print
        </Button>
        <Button
          className={classes.activateButton}
          variant="contained"
          color="primary"
          onClick={openProof}
        >
          Open Proof
        </Button>
      </div>
    </form>
  );

  const LoadingSpinner = (
    <div className={classes.verticalAlign}>
      <CircularProgress color={'primary'} />
      <Typography style={{ marginTop: '1em' }} color="primary">
        {currentState}
      </Typography>
    </div>
  );

  return isLoading ? LoadingSpinner : Form;
};

export default observer(Details);

const Test = () => <span></span>;
