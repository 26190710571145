import { createMuiTheme, Theme, ThemeOptions } from '@material-ui/core/styles';
import createMixins, { Mixins } from '@material-ui/core/styles/createMixins';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

declare module '@material-ui/core/styles/createMixins' {
  interface Mixins {
    wrapper: CSSProperties;
    fullstopWrapper: CSSProperties;
    defaultButton: CSSProperties;
    wideButton: CSSProperties;
    overflowEllipsis: CSSProperties;
    verticalAlign: CSSProperties;
  }
}

export interface IThemeOptions extends ThemeOptions {}

export interface ITheme extends Theme {
  mixins: Mixins;
}

const { breakpoints, spacing } = createMuiTheme();

const mixins = createMixins(breakpoints, spacing, {
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  fullstopWrapper: {
    height: '80%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  defaultButton: {
    width: '148px',
    fontSize: '1.125em',
    fontWeight: 700,
  },
  wideButton: {
    width: '184px',
  },
  overflowEllipsis: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  verticalAlign: {
    display: 'flex',
    alignItems: 'center',
  },
});

const theme = createMuiTheme({
  mixins,
  palette: {
    primary: {
      main: '#0097a7',
    },
  },
  typography: {
    fontFamily: '"Nunito Sans", sans-serif',
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 600,
    fontWeightBold: 700,
  },
} as IThemeOptions);

export default theme;
