import { makeAutoObservable } from 'mobx';
import api from '../api';
import { IError } from '../models/error';
import { ICreateRecord, IRecord } from '../models/record';
import errorNotify from '../utils/notifications/errorNotify';

export default class RecordStore {
  records: IRecord[] = [];
  recordsLoading = false;
  recordDetailsLoading = true;
  searchOngoing = false;
  selectedRecord: IRecord | null = null;
  pendingRecord: IRecord | null = null;
  pendingRecordLoading = true;
  error: IError | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  setError = (error: IError | null) => {
    this.error = error;
  };

  setRecords = (records: IRecord[]) => {
    this.records = records;
  };

  setSelectedRecord = (record: IRecord | null) => {
    this.selectedRecord = record;
  };

  setPendingRecord = (record: IRecord | null) => {
    this.pendingRecord = record;
  };

  setRecordsLoading = (recordsLoading: boolean) => {
    this.recordsLoading = recordsLoading;
  };

  setRecordDetailsLoading = (recordDetailsLoading: boolean) => {
    this.recordDetailsLoading = recordDetailsLoading;
  };

  setPendingRecordLoading = (pendingRecordLoading: boolean) => {
    this.pendingRecordLoading = pendingRecordLoading;
  };

  setSearchOngoing = (searchOngoing: boolean) => {
    this.searchOngoing = searchOngoing;
  };

  loadRecords = async () => {
    try {
      this.setError(null);
      this.setRecordsLoading(true);
      const { value } = await api.Records.list();
      this.setRecords(value);
    } catch (error) {
      this.setError({
        title: 'Error',
        message:
          'Loading the records was unsuccessful. Please try again or refresh.',
      });
    } finally {
      this.setRecordsLoading(false);
      this.setSearchOngoing(false);
    }
  };

  activateRecord = async (
    record: ICreateRecord
  ): Promise<IRecord | undefined> => {
    try {
      this.setRecordsLoading(true);
      const { value } = await api.Records.create(record);
      return value;
    } catch (error) {
      errorNotify(
        'Record activation failed. Please try again or contact support.'
      );
    } finally {
      this.setRecordsLoading(false);
    }
  };

  details = async (id: string) => {
    try {
      this.setError(null);
      this.setSelectedRecord(null);
      this.setRecordDetailsLoading(true);
      const { value } = await api.Records.details(id);
      this.setSelectedRecord(value);
    } catch (error) {
      this.setError({
        title: 'Error',
        message:
          'Loading the record was unsuccessful. Please try again or refresh.',
      });
    } finally {
      this.setRecordDetailsLoading(false);
    }
  };

  toggleBookmark = async () => {
    if (!this.selectedRecord) return;
    this.setRecordDetailsLoading(true);
    const { id, bookmark } = this.selectedRecord;

    try {
      if (bookmark) {
        await api.Bookmarks.delete(id);
      } else {
        await api.Bookmarks.create(id);
      }

      this.setSelectedRecord({
        ...this.selectedRecord,
        bookmark: !bookmark,
      });
    } catch (_err) {
      errorNotify(
        'Failed to update bookmark setting. Please try again or contact support.'
      );
    }
    this.setRecordDetailsLoading(false);
  };

  searchRecords = async (searchValue: string) => {
    try {
      this.setSearchOngoing(true);
      const { value } = await api.Records.list(`?query=${searchValue}`);
      this.setRecords(value);
    } catch (error) {
      errorNotify('Record search failed. Please try again or contact support.');
    } finally {
      this.setSearchOngoing(false);
    }
  };

  getPendingRecord = async () => {
    try {
      this.setError(null);
      this.setPendingRecordLoading(true);
      const { value } = await api.Records.getPending();
      if (value.length) {
        this.setPendingRecord(value[0]);
      } else {
        this.setPendingRecord(null);
        this.setPendingRecordLoading(false);
      }
    } catch (error) {
      this.setError({
        title: 'Error',
        message:
          'Checking for pending records was unsuccessful. Please try again or refresh.',
      });
    } finally {
      this.setPendingRecordLoading(false);
    }
  };
}
