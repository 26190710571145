import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import env from '@beam-australia/react-env';
import { store } from '../stores/store';

axios.defaults.baseURL = env('NOTRZR_API');

const delay = (delay: number) => {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
};

axios.interceptors.request.use((config) => {
  const token = store.commonStore.accessToken;
  if (token) config.headers.Authorization = `Bearer ${token}`;
  return config;
});

axios.interceptors.request.use((config) => {
  if (store.commonStore.cookiePolicyAccepted) {
    config.headers['Cookie-Policy-Accepted'] = true;
  } else {
    config.headers['Cookie-Policy-Accepted'] = false;
  }
  return config;
});

axios.interceptors.response.use(async (response) => {
  if (process.env.NODE_ENV === 'development') await delay(1000);
  return response;
});

interface IMeta {
  size?: number;
  page?: number;
}

interface IApiError {
  code: string;
  message: string;
}

interface IResponseBody<T> {
  value: T;
  meta?: IMeta;
  error?: IApiError;
}

const responseBody = <T>(response: AxiosResponse<T>): T => response.data;

const http = {
  get: <T>(url: string, config?: AxiosRequestConfig | undefined) =>
    axios.get<IResponseBody<T>>(url, config).then(responseBody),
  post: <T>(url: string, config?: AxiosRequestConfig | undefined) =>
    axios.post<IResponseBody<T>>(url, config).then(responseBody),
  patch: <T>(url: string, config?: AxiosRequestConfig | undefined) =>
    axios.patch<IResponseBody<T>>(url, config).then(responseBody),
  put: <T>(url: string, config?: AxiosRequestConfig | undefined) =>
    axios.put<IResponseBody<T>>(url, config).then(responseBody),
  delete: <T>(url: string, config?: AxiosRequestConfig | undefined) =>
    axios.delete<IResponseBody<T>>(url, config).then(responseBody),
};

export default http;
