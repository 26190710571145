import Typography from '@material-ui/core/Typography';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { ITheme } from '../../common/theme';

const useStyles = makeStyles((theme: ITheme) =>
  createStyles({
    logo: {
      fontWeight: theme.typography.fontWeightBold,
      fontSize: '2rem',
      cursor: 'pointer',
    },
    appbarLogoWrapper: {
      marginLeft: `-${theme.spacing(6)}px`,
      marginRight: 'auto',
    },
    logoLeft: {
      color: theme.palette.primary.main,
    },
    logoRight: {
      color: theme.palette.text.primary,
    },
    sidebarLogoWrapper: {
      padding: theme.spacing(2),
      paddingBottom: 0,
    },
  })
);
interface IProps {
  inSidebar: boolean;
}
const Logo = ({ inSidebar }: IProps) => {
  const classes = useStyles();

  return inSidebar ? (
    <Typography
      className={`${classes.sidebarLogoWrapper} ${classes.logo}`}
      variant="h1"
      noWrap
    >
      <span className={classes.logoLeft}>VAX</span>
      <span className={classes.logoRight}>NTR</span>
    </Typography>
  ) : (
    <Typography
      className={`${classes.appbarLogoWrapper} ${classes.logo}`}
      variant="h1"
      noWrap
    >
      <span className={classes.logoLeft}>VAX</span>
      <span className={classes.logoRight}>NTR</span>
    </Typography>
  );
};

export default Logo;
