import React from 'react';
import { useState } from 'react';
import {
  createStyles,
  makeStyles,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Button,
  CircularProgress,
  Typography,
} from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { ITheme } from '../../common/theme';
import clsx from 'clsx';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { useStore } from '../../common/stores/store';
import { detailsPath } from '../../common/constants/routes';

const useStyles = makeStyles((theme: ITheme) =>
  createStyles({
    verticalAlign: {
      ...theme.mixins.verticalAlign,
      height: '100%',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    formWrapper: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      padding: theme.spacing(1),
      alignItems: 'center',
    },
    formControl: {
      marginBottom: theme.spacing(2),
      maxWidth: '540px',
      width: '100%',
      border: '2px solid',
      borderColor: theme.palette.primary.main,
      borderRadius: '6px',
      padding: theme.spacing(2),
      '& .Mui-disabled': {
        color: 'rgba(0, 0, 0, 0.75)',
      },
      '& .MuiInput-underline:before': {
        borderBottomColor: theme.palette.primary.main,
      },
      '& .MuiInput-underline:hover:before': {
        borderBottomColor: theme.palette.primary.main,
      },
    },
    doctorField: {
      border: 0,
      width: '20%',
      alignSelf: 'flex-end',
      marginBottom: '3em',
      [theme.breakpoints.down('sm')]: {
        width: 'auto',
        alignSelf: 'initial',
        // width: '50%',
      },
    },
    formLabel: {
      padding: theme.spacing(2),
    },
    textField: {
      maxWidth: '100%',
    },
    activateButton: {
      // alignSelf: 'flex-start',
      ...theme.mixins.defaultButton,
      ...theme.mixins.wideButton,
    },
  })
);

const NewRecordForm = () => {
  const classes = useStyles();
  const { navigationStore } = useStore();
  const [selectedIdType, setSelectedIdType] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [currentState, setCurrentState] = useState('Submitted');
  const [selectedDate, setSelectedDate] = React.useState<Date | null>(
    new Date(Date.now() + 3600 * 1000 * 24 * (30 * 3 + 31 * 3))
  );

  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);
  };

  const handleSelect = (event: React.ChangeEvent<{ value: unknown }>) => {
    if (event.target.value && typeof event.target.value == 'string')
      setSelectedIdType(event.target.value);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log(event.target);
    console.log(event.target.dataset);
  };

  const sign = () => {
    setIsLoading(true);
    setTimeout(() => setCurrentState('Committed'), 1300);
    setTimeout(() => setCurrentState('Confirmed'), 2600);
    setTimeout(() => {
      navigationStore.push(detailsPath);
    }, 3200);
  };

  const Form = (
    <form className={classes.formWrapper} autoComplete="off">
      <TextField
        color="primary"
        size={'small'}
        label={`Doctor`}
        variant="standard"
        className={clsx(classes.formControl, classes.doctorField)}
        InputLabelProps={{
          className: classes.formLabel,
        }}
        disabled={true}
        value={`Dr. Sue Park`}
      />

      <FormControl className={classes.formControl}>
        <InputLabel className={classes.formLabel} id="wallet-for-record-label">
          Photo ID Type
        </InputLabel>
        <Select
          labelId="wallet-for-record-label"
          id="wallet-select"
          value={selectedIdType}
          onChange={handleSelect}
        >
          <MenuItem value="Passport">Passport</MenuItem>
          <MenuItem value="ID Card">ID Card</MenuItem>
          <MenuItem value="Drivers License">Drivers License</MenuItem>
        </Select>
      </FormControl>

      <TextField
        color="primary"
        size={'small'}
        label={`Photo ID Provided`}
        data-fieldid={'photoId'}
        variant="standard"
        className={classes.formControl}
        InputLabelProps={{
          className: classes.formLabel,
        }}
      />

      <TextField
        color="primary"
        size={'small'}
        label={`Reason`}
        disabled={true}
        value={'SARS-CoV-2'}
        variant="standard"
        className={classes.formControl}
        InputLabelProps={{
          className: classes.formLabel,
        }}
      />

      <TextField
        color="primary"
        size={'small'}
        label={`Manufacturer`}
        data-fieldid={'photoId'}
        variant="standard"
        className={classes.formControl}
        InputLabelProps={{
          className: classes.formLabel,
        }}
      />

      <TextField
        color="primary"
        size={'small'}
        label={`Drug Name`}
        data-fieldid={'photoId'}
        variant="standard"
        className={classes.formControl}
        InputLabelProps={{
          className: classes.formLabel,
        }}
      />

      <TextField
        datatype="lot-number"
        inputProps={{
          'data-fieldid': 'lot-number',
        }}
        onChange={handleChange}
        color="primary"
        size={'small'}
        label={`Lot Nr.`}
        data-fieldid={'photoId'}
        variant="standard"
        className={classes.formControl}
        InputLabelProps={{
          className: classes.formLabel,
        }}
      />

      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          className={classes.formControl}
          InputLabelProps={{
            className: classes.formLabel,
          }}
          disableToolbar
          variant="inline"
          format="MM/dd/yyyy"
          margin="normal"
          id="date-picker-inline"
          label="Exp. Date"
          value={selectedDate}
          onChange={handleDateChange}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
      </MuiPickersUtilsProvider>

      <Button
        className={classes.activateButton}
        variant="contained"
        color="primary"
        onClick={sign}
      >
        Sign
      </Button>
    </form>
  );

  const LoadingSpinner = (
    <div className={classes.verticalAlign}>
      <CircularProgress color={'primary'} />
      <Typography style={{ marginTop: '1em' }} color="primary">
        {currentState}
      </Typography>
    </div>
  );

  return isLoading ? LoadingSpinner : Form;
};

export default observer(NewRecordForm);
